.radioCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
}

.radiocard,
.radioCard * {
  cursor: pointer;
}

.radioCard:hover {
  background: #f6f5ff;
}

.radioCard[aria-selected='true'] {
  border: 1px solid #bab0fd;
  background: #f6f5ff;
}

.radioCard input {
  background-color: transparent;
}
