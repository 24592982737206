.c-edits-cart-table {
  max-height: 580px;
  overflow: auto;
}

.no-padding-modal .mantine-Modal-content {
  padding: 0;
}

.large-height-modal .mantine-Modal-content {
  max-height: calc(100dvh - (2dvh * 2));
}

.no-padding-modal .mantine-Modal-body {
  padding: 0;
}

.no-header-modal .mantine-Modal-header {
  height: 0;
  min-height: 0;
  max-height: 0;
  margin: 0;
  padding: 0 !important;
}

.no-header-modal .mantine-Modal-close {
  display: none;
}

.mantine-Modal-content {
  padding: 6px;
}

.mantine-Modal-title {
  font-weight: 600;
  line-height: 1.5;
}

.bordered-header .mantine-Modal-header {
  border-bottom: 1px solid #e8ebee;
  margin-right: 0;
  padding: 20px 24px;
}

.small-header .mantine-Modal-title {
  font-weight: 500;
  font-size: 14px;
  color: #706c89;
}

.c-edits-cart-modal .flag-container {
  height: 112px;
  width: 112px;
  border-radius: 24px;
  padding: 24px;
  border: 8px solid #f4f4f6;
  background: #e9e8ed;
  color: #9b98ae;
}

.c-cart .mantine-Grid-root {
  margin: 0;
}

.c-cart
  .mantine-badge-root:not([data-testid='od-label-badge'])
  .mantine-Badge-inner {
  text-transform: none;
  color: #65646e;
  padding: 5px 0;
  cursor: pointer;
}

.c-cart .mantine-Badge-outline {
  background-color: white;
}
.c-cart .mantine-Badge-filled {
  background-color: #d5d4d8;
}

.cv-modal .mantine-Modal-content {
  min-width: 60vw;
}

.cv-modal .mantine-Modal-header {
  padding: 20px 24px;
}

.od-modal .mantine-Modal-content {
  min-width: 90vw;
  top: 0;
  left: 0;
}
.od-modal .od-polygon[aria-expanded='false'] polygon {
  fill-opacity: 0;
}
.od-modal .od-polygon[aria-expanded='true'] polygon {
  fill-opacity: 0.2;
}

.ss-modal .mantine-Modal-content {
  min-width: 90vw;
  top: 0;
  left: 0;
}

.ss-modal .ss-polygon[aria-expanded='true'] polygon {
  fill-opacity: 0.6;
}

.template-code-modal .mantine-Modal-content {
  min-width: 50vw;
}

.prompt-data-entry-modal .mantine-Modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid #e8ebee;
}

.prompt-data-entry-modal.mantine-Modal-close {
  color: #b1afc0;
}

.prompt-data-entry-modal .mantine-Modal-close svg {
  height: 24px;
  width: 24px;
  color: #b1afc0;
}

.with-zindex {
  z-index: 9998;
}
